import React, {useState} from 'react';
import {Link} from "react-router-dom";

const PublicNavigation = () => {

    const [collapseMode, setCollapseMode] = useState(true);

    const handleToggleCollapseMode = () => {
        setCollapseMode(!collapseMode)
    };

    return <div>

        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white">
            <div className="container py-3 px-4">
                <a href="https://www.voucherjet.com" className="navbar-brand">
                    <img style={{"height": "24px"}} src="/assets/images/voucherjet-logo.png" alt="voucherjet logo"/>
                </a>
                <div className="d-flex ml-auto">
                    <button onClick={handleToggleCollapseMode} className="navbar-toggler" type="button"
                            data-toggle="collapse" data-target="#globalNavbar"
                            aria-controls="globalNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                </div>
                <div className={collapseMode ? "collapse navbar-collapse" : "navbar-collapse"}>
                    <ul className="navbar-nav mr-auto order-1">

                    </ul>
                    <ul className="navbar-nav d-none d-lg-flex ml-2 order-3">
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">
                                Login
                            </Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-lg-none">
                        <li className="nav-item-divider"/>
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">
                                Login
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <div className="mt-3">
            &nbsp;<br/>
            &nbsp;<br/>
        </div>

        <div className="container alert alert-danger mt-5" role="alert">
            <b>VoucherJet will be ending its service by 2025-03-31.</b> <br/>
            <br/>
            Please make sure you deactivate all configurations up to this date for a smooth shutdown.<br/>
            Also export all your codes and contacts if needed as the data will be deleted after the
            shutdown is completed.<br/>
            <br/>
            If you have any questions, please reach out: <a className=" text-dark hover:underline-jv-link"
                                                            href="mailto:contact@voucherjet.com">contact@voucherjet.com</a>
            <br/>
        </div>

    </div>;
};

export default PublicNavigation;