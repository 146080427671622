import React from 'react'


const Footer = () => {
    return (
        <footer className="bg-vj-beige mt-5  border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 p-4">
                        <span className="font-weight-bold">VoucherJet</span>
                        <div className="text-small text-dark mt-3">
                            The voucher and coupon distribution service.
                        </div>
                        <div className="text-small text-dark mt-2">
                            &copy; 2025 Inaudi Tech
                        </div>
                        <div className="mt-2">
                            <a className="text-small text-dark  hover:underline-jv-link" target="_blank" rel="noreferrer noopener" href="https://www.voucherjet.com/en/terms" >Terms &amp; Conditions</a>
                        </div>
                        <div className="mt-2">
                            <a className="text-small text-dark  hover:underline-jv-link" target="_blank" rel="noreferrer noopener" href="https://www.voucherjet.com/en/privacy" >Privacy Policy</a>
                        </div>
                        <div className="mt-2">
                            <a className="text-small text-dark  hover:underline-jv-link" target="_blank" rel="noreferrer noopener" href="https://www.voucherjet.com/en/imprint" >Imprint</a>
                        </div>
                    </div>
                    <div className="col-md-3 p-4">
                        <span className="font-weight-bold">Help &amp; Contact</span>
                        <div className="text-small text-dark mt-3">
                            Need any help? Just drop us a line: <br/>
                            <a className=" text-dark hover:underline-jv-link" href="mailto:contact@voucherjet.com">contact@voucherjet.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer